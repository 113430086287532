import {ProductType} from "../interfaces/products.interface";

export const PRODUCT_TYPES = [
  {
    value: ProductType.PHONE_CASE,
    label: "Phone case"
  },
  {
    value: ProductType.SCREEN_PROTECTOR,
    label: "Screen protector"
  },
  {
    value: ProductType.TSHIRT,
    label: "T-Shirt"
  },
  {
    value: ProductType.MACBOOK_CASE,
    label: "MacBook case"
  },
  {
    value: ProductType.IPAD_CASE,
    label: "iPad case"
  },
  {
    value: ProductType.AIRPODS_CASE,
    label: "AirPods case"
  },
  {
    value: ProductType.PUZZLE,
    label: "Puzzle"
  },
  {
    value: ProductType.MAGNET,
    label: "Magnet"
  },
  {
    value: ProductType.MAGSAFE_WALLET,
    label: "Magsafe Wallet"
  }
];
